
import { useState } from 'react'
import useStallholderTransactions from '@hooks/useStallholderTransactions';
import { ReactComponent as AnimatedSpinner } from '@sharedassets/three_dots_animared_spinner.svg';
import { Stallholder } from "@sharedtypes/Stallholder";
import TransactionsSummary from "@shared/TransactionsSummary";
import {COLUMNS} from "@shared/TransactionTableColumns";

type StallholderSummaryProps = {
  stallholder: Stallholder;
  start: string;
  end:string;
  showPrintButton?: boolean;
};

export const StallholderSummary = ({ stallholder, start, end, showPrintButton = true }: StallholderSummaryProps) => {

  const {isLoading, data, refetch} = useStallholderTransactions(start, end, stallholder.dealer_id);
  const [columns] = useState([COLUMNS[1], COLUMNS[2], COLUMNS[4], COLUMNS[3], COLUMNS[5], COLUMNS[10], COLUMNS[11]]);
  const [title] = useState(stallholder.name + "(" + stallholder.dealer_id + ")");

  return (
    <>
    {
      isLoading ?
      <div className="h-8 w-8"><AnimatedSpinner /></div> :
      <div className="mt-4"> 
        <TransactionsSummary transactions={data} showContraNet={true} columns={columns} title={title} showPrintButton={showPrintButton}/>
      </div>

    }
    </>
  );
}

export default StallholderSummary;
