
import { useState, Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import useAdminAPIService from "@hooks/useAdminAPIService";
import { Stallholder } from "@sharedtypes/Stallholder";
import log from "loglevel";

type ChargesProps = {
  show: boolean;
  date: Date;
  hide: () => void;
  stallholder: Stallholder;
  type: number; // 0=charge fllor walk, 1=pay floor walk, 2=charge rent
};


const Charges = ({show, date, hide, stallholder, type }: ChargesProps) => {

  const [processingCharge, setProcessingCharge] = useState(false);
  const [description, setDescription] = useState("");

  const apiService  = useAdminAPIService();

  function close() {
    setDescription("");
    hide();
  }

  async function charge() {

    if (type === 0)
      chargeWalkFee();
    else if (type === 1)
      payWalkFee();
    else 
      chargeRent();
  }

  async function chargeRent() {

    const fee = parseFloat(description);

    log.debug("chargeRent: " + fee, stallholder.dealer_id, "997", "Rent", "Rent " + stallholder.dealer_id, date);
    await doCharge(fee, stallholder.dealer_id, "997", "Rent", "Rent " + stallholder.dealer_id);
  }

  async function chargeWalkFee() {
      log.debug("chargeWalkFee");
      await doCharge(35, stallholder.dealer_id, "998", description, "Floor walking fee from " + stallholder.dealer_id);
  }

  async function payWalkFee() {
    log.debug("payWalkFee");
    await doCharge(35, "998", stallholder.dealer_id, "Floor walking payment to "  + stallholder.dealer_id, description);
  }

  async function doCharge(amount: number, from: string, to: string, fromDescription: string, toDescription: string) {

    setProcessingCharge(true);
    await apiService.fetch("addCharge", 
                            {
                              amount: amount, 
                              from: from, 
                              to: to, 
                              fromDescription: fromDescription,
                              toDescription: toDescription, 
                              date: date
                            }
                          )
      .then((data) => {
        log.info("Updated payment dates " + JSON.stringify(data));
        window.location.reload();
      })
      .catch(function (error) {
        log.info("Error updating payment dates:" + error);
      }
    )

    setProcessingCharge(false);    
    close();
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = event.target;
    setDescription(value);
  };



  return (
    <div className="">
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <div>
                    {
                      type === 0 ?
                       <ChargeWalkingFee stallholder={stallholder}/> :
                      <>
                        {
                          type === 1 ?
                          <PayWalkingFee stallholder={stallholder}/> :
                          <ChargeRent stallholder={stallholder}/>
                        }
                      </>
                    }

                    <div className="mt-2">
                      <input
                        id="description"
                        name="description"
                        onChange={handleDescriptionChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-300 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>        

                  <div className="mt-4">

                    <button
                        type="button"
                        className="ml-4 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-100 disabled:border-red-900 border-1 border-emerald-900 p-4 text-emerald-500 shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={charge}
                        disabled={processingCharge}
                      >
                        {
                          type === 1 ?
                          <>Pay</> :
                          <>Charge</>
                        }
                        
                      </button>
                    <button
                      type="button"
                      className="ml-4 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-100 disabled:border-red-900 border-1 border-emerald-900 p-4 text-emerald-500 shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={close}
                      disabled={processingCharge}
                      >
                      Cancel
                    </button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

type ChargeWalkingFeeProps = {
  stallholder: Stallholder;
};


const ChargeWalkingFee = ({stallholder }: ChargeWalkingFeeProps) => {

  return (
    <>
    Add a £35 fee for floor walking to stallholder {stallholder.dealer_id}. <br /><br />
    Optional description for the dealer remittance:
    </>
  );
}

type PayWalkingFeeProps = {
  stallholder: Stallholder;
};


const PayWalkingFee = ({stallholder }: PayWalkingFeeProps) => {

  return (
    <>
    Pay a £35 fee for floor walking to stallholder {stallholder.dealer_id}. <br /><br />
    Optional description for the dealer remittance:
    </>
  );
}

type ChargeRentProps = {
  stallholder: Stallholder;
};


const ChargeRent = ({stallholder }: ChargeRentProps) => {

  return (
    <>
    Add the fee you enter, to the dealers account:
    </>
  );
}


export default Charges;
