import useAdminAPIService from "@hooks/useAdminAPIService";
import { useQuery } from '@tanstack/react-query'
import log from "loglevel";

/*
function remap(data: any) {

  //const recent : Array<Date> = [];
  const recent = {
    0: [],
    1: [],
    2: []
  }
  data.forEach((mark: {}) => {
        
    if (mark !== undefined) {
      //if (mark.0 !== undefined) recent[0].push(mark[0]);
      if (mark.hasOwnProperty(0)) {
        const propertyName: keyof  = "age";
        recent[0].push(mark[0])
      }

    }
    
  });
  
  return recent;
}
*/

const useRecentReconciliations = () => {
  const apiService  = useAdminAPIService();

  return useQuery( {
    queryKey: ['recentReconciliations'], 
    queryFn: async () => apiService.fetch("getRecentMarks", {}),
    //select: (response) => remap(response),
    staleTime: 10000,
    refetchInterval: 60000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })
}

export default useRecentReconciliations;
