import { useQuery } from '@tanstack/react-query'
import useAdminAPIService from "@hooks/useAdminAPIService";
import log from "loglevel";
import {createDailySales} from '@sharedtypes/SalesSummary';

type SalesQueryType = (
  month: string
) => any;

const useSales: SalesQueryType = (
  month
) => {

  const apiService  = useAdminAPIService();
  const key = ['sales:', month];
  return useQuery({
    queryKey: key, 
    queryFn: async () => 
      apiService.fetch("getSales", {month: month})
      .then((data: any) => {  
          log.debug("useSales, result:", data);
          const sales = createDailySales(data);

          return sales;
      })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 1000000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
})
}

export default useSales;
