export const CASH = 0;
export const DEBIT = 1;
export const CREDIT = 2;
export const CONTRA = 3;
export const CONTRA_CASH = 4;

export function getDescription(paymentType: number) {

    switch (paymentType) {
        case CASH : return "Cash";
        case DEBIT : return "Debit Card";
        case CREDIT : return "Credit Card";
        case CONTRA : return "Contra payment";
        case CONTRA_CASH : return "Payment from till";
        default: return "Unknown payment type";
    }
}
