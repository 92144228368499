
import { useState, useEffect, useRef } from 'react'
import log from "loglevel";
import dateFormat from "dateformat";
import {PrinterIcon } from '@heroicons/react/24/outline'

import {CompletedTransaction} from "@sharedtypes/CompletedTransactions";

import Table, {Column} from "@shared/Table";
import {CASH, DEBIT, CREDIT, CONTRA, CONTRA_CASH} from '@constants/payment_types'
import {GOOD, REFUNDED} from '@constants/status_types'
import { formatPrice, formatPriceMaskingZero, createTransactionSummary, TransactionSummary } from "@shared/Utils";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

type TransactionsProps = {
  transactions: CompletedTransaction[];
  columns?: Column[];
  showContraNet? : boolean;
  title?: string;
  showPrintButton?: boolean;
};

const TransactionsSummary = ({ transactions, columns, showContraNet, title, showPrintButton = true }: TransactionsProps) => {

  const [cols] = useState(columns === undefined ? defaultColumns : columns);
  const printable = useRef<HTMLInputElement>(null);

  return (
    <>
      {
        (showPrintButton) ?
        <div className="flex justify-end">
          <ReactToPrint content={() => printable.current}>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <button 
                  onClick={handlePrint}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <PrinterIcon className="w-6 h-6"/>
                    Print transactions & summary
                </button>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div> :
        <></>
      }

      <div ref={printable} className="m-2 mr-8">
        {
          title ? <h1 className="font-bold text-xl flex justify-start">{title}</h1> : <></>
        }
        <TransactionsByTypeTable label="Debit" transactions={transactions}  paymentType={DEBIT} statusType={GOOD} columns={cols}/>
        <TransactionsByTypeTable label="Refunded Debit" transactions={transactions}  paymentType={DEBIT} statusType={REFUNDED} columns={cols}/>
        <TransactionsByTypeTable label="Credit" transactions={transactions}  paymentType={CREDIT} statusType={GOOD} columns={cols}/>
        <TransactionsByTypeTable label="Cash" transactions={transactions} paymentType={CASH} statusType={GOOD} columns={cols}/>
        <TransactionsByTypeTable label="Contra" transactions={transactions} paymentType={CONTRA} statusType={GOOD} columns={cols}/>
        <TransactionsByTypeTable label="Cash Contra" transactions={transactions} paymentType={CONTRA_CASH} statusType={GOOD} columns={cols}/>

        <Summary transactions={transactions} showContraNet={showContraNet ? showContraNet : false}/>
      </div>
    </>
  );
}

const defaultColumns = [
  {
    "id": "stallholder",
    "label": "Dealer",
    "cellStyle": (row: number) => {return "print:p-0 print:m-0 print:text-xs bg-white py-1"},
    "headerStyle": "",
    "format": (cell: any, row: {}, index: number) => { 
      return cell;
    }
  },
  {
    "id": "price",
    "label": "Gross",
    "cellStyle": (row: number) => {return "print:p-0 print:m-0 print:text-xs bg-white pl-2 py-1 text-right"},
    "headerStyle": "pl-2 py-1 text-right",
    "format": (cell: any, row: {}, index: number) => { 

      const transaction = row as CompletedTransaction;
      return formatPrice(transaction.price);
    }
  },
  {
    "id": "net",
    "label": "Net",
    "cellStyle": (row: number) => {return "print:pr-4 print:p-0 print:m-0 print:text-xs bg-white pl-2 pr-4 py-1 text-right"},
    "headerStyle": "print:pr-4 pr-4 pl-2 py-1 text-right",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPrice(cell);
    }
  },
  {
    "id": "stockid",
    "label": "Stock ID",
    "cellStyle": (row: number) => {return "print:p-0 print:m-0 print:text-xs bg-white pl-2 py-1"},
    "headerStyle": "py-1 text-left",
    "format": (cell: any, row: {}) => { 
      return (
        <p className=""> 
          {cell}
        </p>
      );
    }
  },
  {
    "id": "description",
    "label": "Description",
    "cellStyle": (row: number) => {return "text-left print:p-0 print:m-0 print:text-xs bg-white py-1"},
    "headerStyle": "py-1",
    "format": (cell: any, row: {}, index: number) => { 
      return (
        <p className=""> 
          {cell}
        </p>
      );
    }
  },
  {
    "id": "timestamp",
    "label": "Time",
    "cellStyle": (row: number) => {return "text-left print:p-0 print:m-0 print:text-xs bg-white py-1"},
    "headerStyle": "pl-2 py-1",
    "format": (cell: any, row: {}, index: number) => { 

      const date = new Date(cell);

      //if (isToday(date)) return <>Today {dateFormat(date, "HH:MM")}</>;
      //else if (isYesterday(date)) return <>Yesterday {dateFormat(date, "HH:MM")}</>;
      return <>{dateFormat(date, "ddd, mmmm dS HH:MM")}</>;
    }
  },
  {
    "id": "total",
    "label": "Total",
    "cellStyle": (row: number) => {return "print:p-0 print:m-0 print:text-xs pl-2 py-1 text-right"},
    "headerStyle": "pl-2 py-1 text-right",
    "format": (cell: any, row: {}, index: number) => { 
      const f = row as CompletedTransaction;

      switch(f.bg) {
        case 0: return <div className={COLORS[0]}>{formatPrice(cell)}</div>;
        case 1: return <div className={COLORS[1]}>{formatPrice(cell)}</div>;
        case 2: return <div className={COLORS[2]}>{formatPrice(cell)}</div>;
        case 3: return <div className={COLORS[3]}>{formatPrice(cell)}</div>;
        case 4: return <div className={COLORS[4]}>{formatPrice(cell)}</div>;
        case 5: return <div className={COLORS[5]}>{formatPrice(cell)}</div>;
        case 6: return <div className={COLORS[6]}>{formatPrice(cell)}</div>;
        case 7: return <div className={COLORS[7]}>{formatPrice(cell)}</div>;
        case 8: return <div className={COLORS[8]}>{formatPrice(cell)}</div>;
        default: return <></>;
      }
    }
  }
]

const COLORS = ["bg-white", "bg-violet-300", "bg-orange-300", "bg-green-300", "bg-yellow-300", "bg-red-300", "bg-cyan-300", "bg-amber-300", "bg-lime-300"];


type TransactionsByTypeTableProps = {
  label: string;
  transactions: CompletedTransaction[];
  paymentType: number;
  statusType: number;
  columns: Column[];
};

function filterTransactionsByType(paymentType: number, statusType: number, transactions: CompletedTransaction[], parent: CompletedTransaction[]) {

  transactions.forEach((transaction: CompletedTransaction) => {

    if (transaction.paymentType === paymentType && transaction.status === statusType) {
      //log.info("in");
      parent.push(transaction);
    }
  });
}

function sortOldestFirst(transactions: CompletedTransaction[]){

  return transactions.sort((obj1: Object, obj2: Object) => {
    const key1 = "timestamp" as keyof typeof obj1;
    const key2 = "timestamp" as keyof typeof obj2;

    if (obj1[key1] > obj2[key2]) {
        return 1;
    }

    if (obj1[key1] < obj2[key2]) {
        return -1;
    }

    return 0;
  });  
}

type Aggregate = {
  [key: string]: number;
};

type AggregateColor = {
  [key: string]: number;
};

const NO_PARENT = "_";

function appendTotalValue(transactions: CompletedTransaction[]) {

  const aggregated: Aggregate = {};
  const aggregatedColor: AggregateColor = {};

  var colorIndex = 1;
  transactions.forEach((transaction: CompletedTransaction) => {
    const index = transaction.id.indexOf("/");

    if (index > -1) {
      const parentID = transaction.id.substring(0, index);
      transaction.parentID = parentID;
      const totalValue = aggregated[parentID];

      if (totalValue === undefined) {
        aggregated[parentID] = transaction.price;
        aggregatedColor[parentID] = colorIndex;
        colorIndex = colorIndex + 1;

        if (colorIndex === COLORS.length) colorIndex = 1;
      }
      else {
        aggregated[parentID] = aggregated[parentID] + transaction.price;
      }
    }
    else {
      transaction.parentID = NO_PARENT;
    }

  });  

  transactions.forEach((transaction: CompletedTransaction) => {
    if (transaction.parentID === NO_PARENT) {
      transaction.total = transaction.price;
      transaction.bg = 0;
    }
    else {
      transaction.total = aggregated[transaction.parentID];
      transaction.bg = aggregatedColor[transaction.parentID];
    }
  });
}

const TransactionsByTypeTable = ({ label, transactions, paymentType, statusType, columns }: TransactionsByTypeTableProps) => {
  
  const [filteredTransactions, setFilteredTransactions] = useState<CompletedTransaction[]>([]);
  

  useEffect(() => {
    log.info("TransactionsByTypeTable", label, transactions.length)
    var filtered : Array<CompletedTransaction> = [];
    filterTransactionsByType(paymentType, statusType, transactions, filtered);
    filtered = sortOldestFirst(filtered);
    appendTotalValue(filtered);
    setFilteredTransactions(filtered);

  return () => {
  };
}, [transactions, paymentType, statusType, label]);   

  return (
    <>
      {
        filteredTransactions.length > 0 ?
        <div>
          <h1 className="print:text-lg print:mb-1 print:mt-1 mb-4 mt-6 text-2xl font-bold text-left">{label}</h1> 
          <div className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-scroll m-2">
            <Table data={filteredTransactions} columns={columns} />
          </div>
        </div>:
        <div>
          <h1 className="print:text-base print:mb-1 print:mt-1 mb-4 mt-6  text-lg text-left text-neutral-400">{label} (0)</h1>
        </div>
      }
    </>
  );
}

function getCellStyle(row: number) {
  switch (row) {
    case 2: return "print:p-0 print:m-0 print:text-xs pl-2 py-1 sm:text-sm md:text-md lg:text-lg font-bold bg-slate-200 text-right";
    case 4: return "print:p-0 print:m-0 print:text-xs pl-2 py-1 sm:text-sm md:text-md lg:text-lg font-bold bg-slate-200 text-right";
    case 7: return "print:p-0 print:m-0 print:text-xs pl-2 py-1 sm:text-sm md:text-md lg:text-lg font-bold bg-slate-200 text-right";
    default: return "print:p-0 print:m-0 print:text-xs pl-2 py-1 sm:text-sm md:text-md lg:text-lg text-right";
  }
}

const summaryCols = [

  {
    "id": "label",
    "label": "",
    "cellStyle": (row: number) => { return getCellStyle(row); },
    "headerStyle": "print:p-0 print:m-0 print:text-xs pl-2 py-1 text-right",
  },
  {
    "id": "gross",
    "label": "Gross",
    "cellStyle": (row: number) => { return getCellStyle(row); },
    "headerStyle": "print:p-0 print:m-0 print:text-xs pl-2  py-1 text-right",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPriceMaskingZero(cell);
    }
  },
  {
    "id": "fees",
    "label": "Fees",
    "cellStyle": (row: number) => { return getCellStyle(row); },
    "headerStyle": "print:p-0 print:m-0 print:text-xs pl-2  py-1 text-right",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPriceMaskingZero(cell);
    }
  },
  {
    "id": "net",
    "label": "Net",
    "cellStyle": (row: number) => { return getCellStyle(row); },
    "headerStyle": "pl-2  py-1 text-right",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPriceMaskingZero(cell);
    }
  },
]

type SummaryProps = {
  transactions: CompletedTransaction[];
  showContraNet : boolean;
};

type SummaryType = {
  label: string;
  gross: number;
  fees: number;
  net: number;
};

const Summary = ({ transactions, showContraNet }: SummaryProps) => {

  const [summary, setSummary] = useState<SummaryType[]>([]);

  useEffect(() => {
    
    const summary : TransactionSummary = createTransactionSummary(transactions);

    log.debug("summary, processed transactions:", summary);

    const newSummary : Array<SummaryType> = [];
    newSummary.push({label: "Debit", gross: summary.debit.gross, fees: summary.debit.fees, net: summary.debit.net});
    newSummary.push({label: "Credit", gross: summary.credit.gross, fees: summary.credit.fees, net: summary.credit.net});
    newSummary.push({label: "Card total", gross: summary.card.gross, fees: summary.card.fees, net: summary.card.net});
    newSummary.push({label: "Cash", gross: summary.cash.gross, fees: summary.cash.fees, net: summary.cash.net});
    newSummary.push({label: "Sales total", gross: summary.sales.gross, fees: summary.sales.fees, net: summary.sales.net});
    newSummary.push({label: (showContraNet) ? "Rent/Contra": "Contra", gross: summary.contra, fees: 0.00, net: summary.contra});
    newSummary.push({label: "Cash Contra", gross: summary.contra_cash, fees: 0.00, net: summary.contra_cash});
 
    if (showContraNet)
      newSummary.push({label: "Payment Due", gross: 0.00, fees: 0.00, net: summary.netSalesLessContra});

    setSummary(newSummary);
    
    return () => {
    };
  }, [transactions]); 

  return (
    <div className="print:mt-12 mt-24 w-96">
      <h1 className="print:text-lg font-extrabold text-2xl text-left">Summary</h1>
      <Table data={summary} columns={summaryCols} />
    </div>
  )
}

export default TransactionsSummary;
