import Shell from "@shared/Shell";
import log from "loglevel";
import useSales from "@hooks/useSales";
import { useEffect } from 'react'

/*
            <MonthlyButton month="01" monthText="January" />
            <MonthlyButton month="02" monthText="Febuary" />
            <MonthlyButton month="03" monthText="March" />
            <MonthlyButton month="04" monthText="April" />
            <MonthlyButton month="05" monthText="May" />
            <MonthlyButton month="06" monthText="June" />
            <MonthlyButton month="07" monthText="July" />
            <MonthlyButton month="08" monthText="August" />
*/

export function Sales() {

  const {isLoading, data, refetch} = useSales("2024-09");
  
  useEffect(() => {

    if (!isLoading) {
      log.debug("Sales:", data);

    }
    return () => {
    };
  }, [data, isLoading]); 


  return (
    <>
      <Shell pageIndex={2}>
        <div>
          <h1 className="font-semibold text-xl">2024</h1>
          <div className="flex flex-wrap">

            <MonthlyButton month="09" year="2024" monthText="September" />
            <MonthlyButton month="10" year="2024" monthText="October" />
            <MonthlyButton month="11" year="2024" monthText="November" />
            <MonthlyButton month="12" year="2024" monthText="December" />
            </div>
        </div>
      </Shell>    
    </>
  );
}

type ButtonProps = {
  month: string;
  year: string;
  monthText: string;
};

const MonthlyButton = ({month, year, monthText}: ButtonProps) => {

  const url = "sales/" + year + "-" + month;

  return(
    <div className="m-2">
      <a className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href={url}>{monthText}</a>
    </div>
  );
}


export default Sales;
