import { useContext } from "react";
import StallholdersContext from "@contexts/StallholdersContext";

function useStallholders() {
  
  const context = useContext(StallholdersContext);
  if (!context)
      throw new Error(
          'No StallholdersContext.Provider found when calling useStallholders.'
      );
  return context;
}

export default useStallholders;
