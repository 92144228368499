import Table from "@shared/Table";
import dateFormat from "dateformat";
import { useState, useEffect, useRef } from 'react'
import {PrinterIcon } from '@heroicons/react/24/outline'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import useStallholders from "@hooks/useStallholders";
import { formatPrice } from "@shared/Utils";
import { StallholderPayment } from "@sharedtypes/StallholderPayment";
import StallholderSummary from "@components/StallholderSummary";
import { Stallholder } from "@sharedtypes/Stallholder";
import { stripTimeFromDate } from "@shared/Utils";

type PaymentReportProps = {
  payments: StallholderPayment[];
  endDate: Date;
};

const PaymentReport = ({payments, endDate }: PaymentReportProps) => {

  const printable = useRef<HTMLInputElement>(null);
  const dealerReports = useRef<HTMLInputElement>(null);
  const stallholderContext = useStallholders();
  const [data, setData] = useState<StallholderPayment[]>([]);
  const [total, setTotal] = useState(0);
  const [printableReports, setPrintableReports] = useState<JSX.Element[]>([]);

  useEffect(() => {
    
    const enrichedPayments = payments.slice(0);
    const reports: JSX.Element[] = [];
    const end = stripTimeFromDate(endDate);
    var newTotal = 0;

    enrichedPayments.forEach(function (item, index) {
      const stallholder = stallholderContext.findStallholderByID(item.id);
      if (stallholder) {
        newTotal += item.amount;
        item.name = stallholder.name;
        item.dealerID = stallholder.dealer_id;
        reports.push(<DealerReport startDate={item.start} endDate={end} stallholder={stallholder}/>)
      }

    });
    setTotal(newTotal);
    setData(enrichedPayments);
    setPrintableReports(reports);

    return () => {
    };
  }, [payments]); 

  const columns = [
    {
      "id": "dealerID",
      "label": "Dealer ID",
      "cellStyle": (row: number) => "px-6 py-1 font-medium text-lg text-gray-900 whitespace-nowrap dark:text-white dark:bg-gray-800",
      "headerStyle": "px-6 py-3 underline"
    },
    {
      "id": "name",
      "label": "Name",
      "cellStyle": (row: number) => "px-6 py-1 text-lg",
      "headerStyle": "px-6 py-4 underline"
    },
    {
      "id": "amount",
      "label": "Payment",
      "cellStyle": (row: number) => "px-6 py-1 text-lg",
      "headerStyle": "px-6 py-4 underline",
      "format": (cell: any, row: {}, index: number) => { 
        const price = cell as number;
        return formatPrice(price);
      }
    },
    {
      "id": "start",
      "label": "After",
      "cellStyle": (row: number) => "px-6 py-1 text-lg",
      "headerStyle": "px-6 py-4 underline"
    }
  ]

  const footer = <tfoot>
      <tr className="font-semibold text-gray-900 dark:text-white">
        <th></th>
        <th></th>
        <th scope="row" className="ml-10 border-t-2 border-b-2 border-zinc-600 px-6 text-base">{formatPrice(total)}</th>
      </tr>
    </tfoot>

  return (
    <>
      <div className="flex justify-end mb-12">
      <ReactToPrint content={() => dealerReports.current}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <button 
                onClick={handlePrint}
                className="mr-6 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PrinterIcon className="w-6 h-6"/>
                  Print dealer reports
              </button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
        <ReactToPrint content={() => printable.current}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <button 
                onClick={handlePrint}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PrinterIcon className="w-6 h-6"/>
                  Print payments summary
              </button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      </div>
      <div ref={printable} >
        <h1 className="font-bold text-2xl flex justify-start mb-6">Payment report for close of business {dateFormat(endDate, "dddd, mmmm dS")}</h1>
        <Table data={data} columns={columns} footer={footer}/>
      </div>
      <div className="hiddenPrint" ref={dealerReports}>
        {printableReports}
      </div>
    </>
  );
}

type DealerReportProps = {
  stallholder: Stallholder;
  endDate: string;
  startDate: string;
};

const DealerReport = ({stallholder, endDate, startDate }: DealerReportProps) => {

  return(
    <>
      <h1 className="text-2xl font-bold">Transaction summary from {startDate} to {endDate}</h1>
      <StallholderSummary start={stallholder.lastPaymentDateCalculation} end={endDate} stallholder={stallholder} showPrintButton={false} />
      <div className="pagebreak" />
    </>
  );
}

export default PaymentReport;
