
import { ReactNode } from "react";

export type Column = {
  id: string | undefined; 
  label: string; 
  cellStyle: (row: number) => string; 
  headerStyle: string;
  format?: (cell: any, row: {}, index: number) => any;
}

type TableProps = {
  columns: Column[];
  data: {}[];
  footer?: ReactNode;
  rowSelected?: (index: number) => void ;
};

export const Table = ({ columns, data, footer, rowSelected }: TableProps) => {

  return (
    <>
      <div className="relative shadow-md sm:rounded-lg">

        <table className="w-full table-auto overflow-scroll">
          <Header columns={columns}/>
          <tbody>
            {
              data.map((row, index) => {
                return (
                  <Row key={index} index={index} data={row} columns={columns} rowListener={rowSelected} ></Row>
                );
              })
            }  
          </tbody>
          {
            (footer)
            ?
              footer
            :
            <></>
          }

        </table>
      </div>
    </>
  );
}

type HeaderProps = {
  columns: Column[];
};

const Header = ({ columns }: HeaderProps) => {

  return (
    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
      <tr >
        {
          columns.map((column, index) => {

            return (
              <th key={index} className={column.headerStyle}>{column.label}</th>
            );
          })
        }   
      </tr>  
    </thead>
  );
}

type RowProps = {
  columns: Column[];
  data: {};
  index: number;
  rowListener?: (index: number) => void ;
};

const Row = ({ columns, data, index, rowListener}: RowProps) => {

  function selectRow(index: number) {

    if (rowListener !== undefined) {
      rowListener(index);
    }
  }

  return (
    <tr className="print:border-0 border-b border-gray-200 dark:border-gray-700" onClick={()=>{selectRow(index)}}>
      {
        columns.map((column, columnIndex) => {

          if (column.id === undefined) {
            const formattedValue = (column.format !== undefined) ?  column.format(undefined, data, index) : undefined;
            return (
              <td key={columnIndex} className={column.cellStyle(index)}>{formattedValue}</td>
            );
          }

          const keyTyped = column.id as keyof typeof column;
          const keyTyped2 = keyTyped as keyof typeof data;
          const value = data[keyTyped2];
          const formattedValue = (column.format !== undefined) ?  column.format(value, data, index) : value;


          return (
            <td key={columnIndex} className={column.cellStyle(index)}>{formattedValue}</td>
          );
        })
      }     
    </tr>
  );
}

export default Table;
