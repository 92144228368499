import dateFormat from "dateformat";
import { isYesterday, dayBefore, dayAfter } from "@shared/Utils";
import { useState, useEffect } from 'react'

import {  
  ChevronLeftIcon, 
  ChevronRightIcon
  } from '@heroicons/react/20/solid';

type DateWidgetProps = {
  date: Date;
  setDate: (date: Date) => void;
};

const DateWidget = ({ setDate, date}: DateWidgetProps) => {

  const [future, setFuture] = useState<boolean>(false);

  function setDayAfter() {
    setDate(dayAfter(date));
  }

  function setDayBefore() {
    setDate(dayBefore(date));
  }

  useEffect(() => {
    
    setFuture(isYesterday(date));
    return () => {
    };
  }, [date]);  

  return (
    <div className="flex mt-4 items-center justify-center">
      <button className="h-12 w-12" onClick={setDayBefore}>
        <ChevronLeftIcon />    
      </button>
      <button className="w-32 rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        {
          (isYesterday(date)) ? <span>Yesterday</span> : dateFormat(date, "d mmmm")
        }
      </button>
      <button 
        className="h-12 w-12 disabled:text-gray-300" 
        onClick={setDayAfter} 
        disabled={future}
      >
        <ChevronRightIcon />    
      </button>
    </div>
  );
}

export default DateWidget;
