import Shell from "@shared/Shell";
import {SpinnerWrapper} from "@shared/Spinner";
import useStallholders from "@hooks/useStallholders";

import dateFormat from "dateformat";
import { stripTimeFromDate, dayBefore } from "@shared/Utils";
import { useState, useEffect } from 'react'
import { Stallholder } from "@sharedtypes/Stallholder";
import log from "loglevel";
import { useLocation } from 'react-router-dom';
import PaymentDue from "@components/PaymentDue";
import { StallholderPayment } from "@sharedtypes/StallholderPayment";
import StallholderSummary from "@components/StallholderSummary";

export function Dealer() {

  const stallholderContext = useStallholders();
  const [stallholder, setStallholder] = useState<Stallholder | undefined >(undefined);
  const location = useLocation();
  const [endDate] = useState(stripTimeFromDate(dayBefore(new Date())));

  useEffect(() => {
    
    try {
      const { pathname } = location;
      const dealerID = pathname.split("/")[2];
      setStallholder(stallholderContext.findStallholderByDealerID(dealerID));
    }
    catch (err) {
      log.error(err);
    }
    return () => {
    };
  }, [setStallholder, location, stallholderContext]); 

  function setPayment(dealerID: string, record: StallholderPayment) {}

  return (
    <>
      <Shell pageIndex={-1}>
        <div>
          {
            stallholder ?
            <>
              <div className="flex justify-end space-x-2"> 
                <span>Last paid:</span>
                <span className="font-semibold">{dateFormat(stallholder.lastPaymentDateCalculation, "dddd, mmmm dS")}</span>
              </div>
              <div className="flex justify-end">
                <div>Next payment: <span className="font-semibold">£</span></div>
                 
                <span className="font-semibold">
                  <PaymentDue start={stallholder.lastPaymentDateCalculation} end={endDate} stallholder={stallholder} setPayment={setPayment}/>
                </span>
              </div>
              <StallholderSummary start={stallholder.lastPaymentDateCalculation} end={endDate} stallholder={stallholder}/>
            </>
            :
            <SpinnerWrapper/>
          }
          
        </div>
      </Shell>    
    </>
  );
}

export default Dealer;
