import { useQuery } from '@tanstack/react-query'
import useAdminAPIService from "@hooks/useAdminAPIService";
import log from "loglevel";

type SearchQueryType = (
  from: string,
  to: string
) => any;

const useSearch: SearchQueryType = (
  from,
  to
) => {

  const apiService  = useAdminAPIService();
  const key = ['search:', from, to];
  return useQuery({
    queryKey: key, 
    queryFn: async () => 
      apiService.fetch("search", {from: from, to: to})
      .then((data: any) => {  
          log.debug("useSearch, result:", data);

          return data;
      })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 1000000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
})
}

export default useSearch;
