import { ReactNode, createContext, useEffect, useState, useMemo } from "react";
import { signOut, fetchAuthSession, getCurrentUser, JWT } from 'aws-amplify/auth';

import log from "loglevel";

export const handleSignOut = async () => {
  await signOut();
}

const AdminAuthContext = createContext<AdminAuthContextValue | undefined>(undefined);

type AdminAuthContextProps = {
  children?: ReactNode
};

type AdminAuthContextValue = {
  user: any | undefined;
  //attributes: any | undefined;
  getJwtToken: () => Promise<any>;
  jwt: any | undefined;
  signedIn: boolean;
};

export const AdminAuthProvider = ({ children }: AdminAuthContextProps) => {

  const [signedIn, setSignedIn] = useState(false);
  const jwt = useMemo(() => getJwtToken2(), []);
  const user = useMemo(() => getUser(), []);

  const getJwtToken = async () => {
    return fetchAuthSession();
  }
  

  useEffect(() => {

    log.info("USER:", user)
    setSignedIn(!!user);

    return () => {
    };
  }, [user]);
  
  const value: AdminAuthContextValue = {
    user,
    //attributes,
    getJwtToken,
    jwt,
    signedIn
  }

  return (
    <AdminAuthContext.Provider value={value}>
      {children}
    </AdminAuthContext.Provider>
  );
};

const getUser = async () => {

  const user  = await getCurrentUser()
  log.info("found user (app): " + JSON.stringify(user));

  if (user === undefined) return null;
  return user;
}

function getJwtToken2() : JWT | null {
  fetchAuthSession()
    .then((token) => {
      //log.debug("found tokens (app): " + JSON.stringify(token));

      if (token.tokens === undefined)
        return null;
      return token.tokens.accessToken;
    })
    .catch(function (error) {
      log.info("auth error:" + error);
      
    })
    return null;
}

export default AdminAuthContext;
