import Shell from "@shared/Shell";
import log from "loglevel";
import useSales from "@hooks/useSales";
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import dateFormat from "dateformat";
import Table from "@shared/Table";
import {createMonthlyTotals, DailySummary} from "@sharedtypes/SalesSummary";
import { formatPrice } from "@shared/Utils";
import {ACCOUNTS, ACCOUNTS_NAME} from "@constants/accounts";

export function MonthlySales() {

  const location = useLocation();
  const [month, setMonth] = useState("");
  const [monthText, setMonthText] = useState("");

  useEffect(() => {
    
    try {
      const { pathname } = location;
      setMonth(pathname.split("/")[2]);
    }
    catch (err) {
      log.error(err);
    }
    return () => {
    };
  }, [setMonth, location]); 

  useEffect(() => {
    
    const date = new Date(month + "-01");
    setMonthText(dateFormat(date, "mmmm yyyy"));
    return () => {
    };
  }, [month, setMonthText]); 

  return (
    <>
      <Shell pageIndex={2}>
        <div>
          <h1 className="font-semibold text-xl mb-8">{monthText}</h1>
          {
            (month.length > 0) ?
            <Report month={month} /> :
            null
          }
        </div>
      </Shell>    
    </>
  );
}

type ReportProps = {
  month: string;
};

const Report = ({month}: ReportProps) => {

  const {isLoading, data, refetch} = useSales(month);
  const [sales, setSales] = useState([]);
  const [columnsAdded, setColumnsAdded] = useState(false);
  const [carry, setCarry] = useState("");

  const [columns, setColumns] = useState([
    {
      "id": "day",
      "label": "Day of month",
      "cellStyle": (row: number) => "px-6 py-1 font-medium text-lg text-gray-900 whitespace-nowrap dark:text-white dark:bg-gray-800",
      "headerStyle": "px-6 py-3 underline",
      "format": (cell: any, row: {}, index: number) => { 
        const dailySummary = row as DailySummary;
        if (dailySummary.day === 0) return <b>Total</b>;
        return cell;
      }
    },
    createColumn("total", "Total"),
    createColumn("debitFees", "Debit Fees"),
    createColumn("creditFees", "Credit fees"),
    createColumn("qpitch", "Qpitch Fees"),
    createColumn("remitted", "Remitted")
  ]);

  useEffect(() => {

    if (!columnsAdded) {
      const newColumns = columns.slice();
      ACCOUNTS.forEach((account: string, index: number) => {

        newColumns.push(    {
          "id": "accounts",
          "label": ACCOUNTS_NAME[index],
          "cellStyle": (row: number) => "px-6 py-1 text-lg",
          "headerStyle": "px-6 py-4 underline",
          "format": (cell: any, row: {}, index: number) => { 
            
            const dailySummary = row as DailySummary;
            const val = cell[account];
            const rounded = formatPrice(val);
            if (dailySummary.day === 0) return <span className="border-t-2 border-b-2 border-zinc-600"><b>{rounded}</b></span>;
            return <>{rounded}</>;
          }
        });
      });

      setColumns(newColumns);
      setColumnsAdded(true);
    }

    return () => {
    };
  }, [sales]); 

  useEffect(() => {

    if (!isLoading) {

      log.debug("Sales:", data);
      const newSales = data.slice();
      const total = createMonthlyTotals(data);
      const newCarry = total.total - (total.accounts["997"] + total.remitted + total.creditFees + total.debitFees + total.qpitch);
      setCarry(formatPrice(newCarry));
      log.debug("total:", total);
      newSales.push(total);
      setSales(newSales);
    }
    return () => {
    };
  }, [data, isLoading]); 

  return(
    <>
      
      {(!isLoading) ?
        <div>
          <Table data={sales} columns={columns} /> 
          <div className="mt-8 text-lg font-bold">
            Carry for this month: {carry}
          </div>
        </div>:
        <></>
      }
    </>
  );
}

function createColumn(id: string, label: string) {
  return {
    "id": id,
    "label": label,
    "cellStyle": (row: number) => "px-6 py-1 text-lg",
    "headerStyle": "px-6 py-4 underline",
    "format": (cell: any, row: {}, index: number) => { 
      const dailySummary = row as DailySummary;

      const rounded = formatPrice(cell);
      if (dailySummary.day === 0) return <span className="border-t-2 border-b-2 border-zinc-600"><b>{rounded}</b></span>;
      return <>{rounded}</>;
    }
  };
}

export default MonthlySales;
