import { useQuery } from '@tanstack/react-query'
import useAdminAPIService from "@hooks/useAdminAPIService";
import log from "loglevel";
import { processTransactions } from "@shared/Utils";

type TransactionsQueryType = (
  start: Date,
  end: Date,
  floor: number
) => any;

const useTransactions: TransactionsQueryType = (
  start, end, floor
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useAdminAPIService();
  const key = ['transactions:', floor, start.toISOString(), end.toISOString()];
  return useQuery({
    queryKey: key, 
    queryFn: async () => 
      apiService.fetch("getTransactions", {start: start, end: end, floor: floor})
      .then((data: any) => {  
          return processTransactions(data);
      })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 100000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })
}

export default useTransactions;
