import { useContext } from "react";
import NavigationContext from "@shared/ContextProviders/NavigationContext";

function useNavigation() {
  
  const context = useContext(NavigationContext);
  if (!context)
      throw new Error(
          'No NavigationContext.Provider found when calling useNavigation.'
      );
  return context;
}

export default useNavigation;
