
import { formatPrice, createTransactionSummary, TransactionSummary } from "@shared/Utils";
import { useState, useEffect } from 'react'
import useStallholderTransactions from '@hooks/useStallholderTransactions';
import { ReactComponent as AnimatedSpinner } from '@sharedassets/three_dots_animared_spinner.svg';
import log from "loglevel";
import { Stallholder } from "@sharedtypes/Stallholder";
import { StallholderPayment } from "@sharedtypes/StallholderPayment";

type PaymentDueProps = {
  stallholder: Stallholder;
  start: string;
  end: string;
  setPayment: (dealerID: string, record: StallholderPayment) => void;
};

const PaymentDue = ({ stallholder, start, end, setPayment}: PaymentDueProps) => {

  const {isLoading, data, refetch} = useStallholderTransactions(start, end, stallholder.dealer_id);
  const [net, setNet] = useState(0);

  useEffect(() => {
    log.debug("PaymentDue:", start, end, stallholder);

    if (!isLoading) {

      const summary : TransactionSummary = createTransactionSummary(data);
  
      setNet(summary.netSalesLessContra);
      const record = {
        end: end,
        start: start,
        id: stallholder.id,
        amount: summary.netSalesLessContra
      }
      setPayment(stallholder.id, record);
    }
    return () => {
    };
  }, [data, isLoading, end, stallholder, start]); 

  return (
    <div className="">
    {
      isLoading ?
      <div className=""><AnimatedSpinner /></div> :
      <div className="text-right">{formatPrice(net)}</div>
    }
    </div>
  );
}


export default PaymentDue;
