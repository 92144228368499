
import Shell from "@shared/Shell";
import useTransactions from "@hooks/useTransactions";
import {FLOORS, FLOORS_LOWER_CASE} from '@constants/floors';
import useRecentReconciliations from "@hooks/useRecentReconciliations";
import TransactionsSummary from "@shared/TransactionsSummary";
import { useEffect, useState } from "react";
import log from "loglevel";
import dateFormat from "dateformat";
import {Spinner} from "@shared/Spinner";
import {RefreshButton} from "@shared/Buttons";
import Tabs from "@shared/Tabs";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function Reconciliations() {

  const {isLoading, data, refetch} = useRecentReconciliations();

  const [groundData, setGroundData] = useState([]);
  const [middleData, setMiddleData] = useState([]);
  const [topData, setTopData] = useState([]);
  const [officeData, setOfficeData] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {

    if (!isLoading) {
      log.info("recs:", data);
      setGroundData(data[0]);
      setMiddleData(data[1]);
      setTopData(data[2]);
      setOfficeData(data[3]);
    }
  
  return () => {
  };
}, [isLoading, data]);

  return (
      <Shell pageIndex={0}>
        <div>
          {
            isLoading ? 
            <div className="grid justify-items-center">
              <h1 className="text-2xl">Loading reconciliation dates...</h1>
              <div className="bg-white text-slate-500 h-32 w-32">
                <Spinner spin={true}/>
              </div> 
            </div>:
            <>
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  onClick={()=> setIndex(0)}
                  type="button"
                  className={classNames(
                    index === 0 ? 'bg-shell-600 text-white font-bold' : ' bg-white text-gray-900 font-semibold ',
                    'relative inline-flex items-center rounded-l-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10"'
                  )}
                >
                  Ground
                </button>
                <button
                  onClick={()=> setIndex(1)}
                  type="button"
                  className={classNames(
                    index === 1 ? 'bg-shell-600 text-white font-bold' : ' bg-white text-gray-900 font-semibold ',
                    'relative -ml-px inline-flex items-center px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10"'
                  )}
                >
                  Middle
                </button>
                <button
                  onClick={()=> setIndex(2)}
                  type="button"
                  className={classNames(
                    index === 2 ? 'bg-shell-600 text-white font-bold' : ' bg-white text-gray-900 font-semibold ',
                    'relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10"'
                  )}
                >
                  Top
                </button>
                <button
                  onClick={()=> setIndex(3)}
                  type="button"
                  className={classNames(
                    index === 3 ? 'bg-shell-600 text-white font-bold' : ' bg-white text-gray-900 font-semibold ',
                    'relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 focus:z-10"'
                  )}
                >
                  Office
                </button>
              </span>
              <div>
                <div
                  className={classNames(
                    index === 0 ? '' : 'hidden',
                    ''
                  )}
                >
                  <FloorReconciliation refetch={refetch} floor={0} dates={groundData}/> 
                </div>
                <div
                  className={classNames(
                    index === 1 ? '' : 'hidden',
                    ''
                  )}
                >
                  <FloorReconciliation refetch={refetch} floor={1} dates={middleData}/>
                </div>
                <div
                  className={classNames(
                    index === 2 ? '' : 'hidden',
                    ''
                  )}
                >
                  <FloorReconciliation refetch={refetch} floor={2} dates={topData}/>
                </div>
                <div
                  className={classNames(
                    index === 3 ? '' : 'hidden',
                    ''
                  )}
                >
                  <FloorReconciliation refetch={refetch} floor={3} dates={officeData}/>
                </div>
              </div>
            </>
          }
          
        </div>
      </Shell>
  );
}

type FloorReconciliationProps = {
  dates: Date[];
  floor: number;
  refetch: () => void;
};

const FloorReconciliation = ({ dates, floor, refetch}: FloorReconciliationProps) => {

  const [showR, setShowR] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [recIndex, setRecIndex] = useState(-2);

  log.debug("rec dates for floor:", floor, dates);

  useEffect(() => {

    setStart(new Date());
    setEnd(new Date());
    setRecIndex(-2);
    setShowR(false);
  
  return () => {
  };
}, [dates, floor]);

  function showNext() {

    setRecIndex(-1);
    setEnd(new Date());
    setStart(new Date(dates[0]));
    setShowR(true);
  }
 
  function show(index: number) {
    
    setRecIndex(index);
    setEnd(new Date(dates[index]));
    setStart(new Date(dates[index+1]));
    setShowR(true);
  } 

  async function refresh() {

    setShowSpinner(true);
    await refetch();
    setShowSpinner(false);
  }

  return(
    <div>
      <div className="flex justify-center items-center align-middle mt-4 mb-10">
        <span className="text-2xl">Recent reconciliation dates for <b>{FLOORS_LOWER_CASE[floor]}</b> floor</span>
        <RefreshButton onClick={refresh} showSpinner={showSpinner}/>
      </div>
      <div className="mb-8 ">
      {
        dates.length > 0 ? 
        <button
          type="button"
          key="next"
          className={classNames(
            recIndex === -1 ? 'bg-orange-50 text-orange-600' : ' bg-indigo-50 text-indigo-600 ',
            'rounded px-2 py-1 font-semibold shadow-sm hover:bg-indigo-100 mr-2'
          )}          onClick={showNext}
        >
          Next
        </button> : 
        <></>
      }
      {
        dates.map((date, index) => {

          if (index < dates.length - 1)
            return (
              <button   
                type="button"
                key={index}
                className={classNames(
                  index === recIndex ? 'bg-orange-50 text-orange-600' : ' bg-indigo-50 text-indigo-600 ',
                  'rounded px-2 py-1 font-semibold shadow-sm hover:bg-indigo-100 mr-2'
                )}
                onClick={()=>{show(index)}}
              >
                {dateFormat(date, "ddd, mmmm dS HH:MM")}
              </button>
            );
          return <span key={index}></span>
        })
      }
      </div>
      {
        showR ?
        <ReconciliationView start={start} end={end} floor={floor} /> :
        <div className="text-xl">Select a date</div>
      }
    </div>
  );
}

type ReconciliationViewProps = {
  start: Date;
  end: Date;
  floor: number;
};

const ReconciliationView = ({ start, end, floor}: ReconciliationViewProps) => {
  const {isLoading, data, refetch} = useTransactions(start, end, floor);
  const [showSpinner, setShowSpinner] = useState(false);
  const title = FLOORS[floor] + " floor reconciliation between " + dateFormat(start, "ddd, mmmm dS HH:MM") + " and " + dateFormat(end, "ddd, mmmm dS HH:MM");


  useEffect(() => {

    if (!isLoading) {
      log.debug("rec data:", data);
    }
  
    return () => {
    };
  }, [isLoading, data]);

  /*
  async function refresh() {
    setShowSpinner(true);
    await refetch();
    setShowSpinner(false);
  }*/

  return (
    <>
    {
      isLoading ?
      <div className="grid justify-items-center ">
        <div className="bg-white text-slate-500 h-32 w-32">
          <Spinner spin={true}/>
        </div> 
      </div> :
      <div>
        <div className="flex justify-center items-center mt-12">
          {/**<RefreshButton onClick={refresh} showSpinner={showSpinner}/>*/}
        </div>
        <TransactionsSummary transactions={data} title={title}/>
      </div>
    }
    </>
  );
}

export default Reconciliations;
