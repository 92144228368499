import { useContext } from "react";
import AdminAPIServiceContext from "@components/ContextProviders/AdminAPIServiceContext";

function useAdminAPIService() {
  
  const context = useContext(AdminAPIServiceContext);
  if (!context)
      throw new Error(
          'No AdminAPIServiceContext.Provider found when calling useAPIService.'
      );
  return context;
}

export default useAdminAPIService;
