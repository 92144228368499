
import {  BackspaceIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import {Spinner} from "@shared/Spinner";

// bg-indigo-600 hover:bg-indigo-500
//const buttonFormat = " font-extrabold text-2xl rounded-full bg-indigo-600 p-4 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ";
const buttonFormat = " font-extrabold text-2xl rounded-full p-4 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ";

type DeleteButtonProps = {
  onClick: () => void
};

const DeleteButton = ({ onClick }: DeleteButtonProps) => {

  const [format] = useState<string>("border-1 border-gray-900 bg-gray-500 hover:bg-gray-400" + buttonFormat);

  return(
  <>
    <button 
      onClick={onClick} 
      className={format}
    >
      <BackspaceIcon className="ml-4 h-8 w-8" />
    </button>
  </>);
}

type CloseButtonProps = {
  onClick: () => void
};

const CloseButton = ({ onClick }: CloseButtonProps) => {


  return(

    <button
      className="text-slate-400 rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      onClick={onClick}
    >
      <div className="flex justify-center items-center">
        <XCircleIcon className="h-6 w-6 mr-2"/>
        Close
      </div>
    </button>
  );
}

type ClearButtonProps = {
  onClick: () => void
};

const ClearButton = ({ onClick }: ClearButtonProps) => {

  const [format] = useState<string>("border-1 border-gray-900 bg-gray-500 hover:bg-gray-400" + buttonFormat);

  return(
  <>
    <button 
      onClick={onClick} 
      className={format}
    >
      <XCircleIcon className="ml-4 h-8 w-8" />
    </button>
  </>);
}

type NumberButtonProps = {
  value: string;
  classOverrides?: string;
  onClick: (number: string) => void
};

const NumberButton = ({ value, onClick, classOverrides }: NumberButtonProps) => {

  function handleClick() {

    onClick(value);
  }

  return(
  <>
    <button 
      onClick={handleClick} 
      className={classOverrides + buttonFormat + classOverrides}
      >
      {value}
    </button>
  </>);
}

type RefreshButtonProps = {
  showSpinner: boolean;
  onClick: () => void
};

const RefreshButton = ({ showSpinner, onClick }: RefreshButtonProps) => {

  return(
  <>
    <button 
      className="items-center ml-4 flex rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      onClick={onClick}
    >
    <div className="mr-2 items-center bg-white text-slate-500 h-6 w-6">
      <Spinner spin={showSpinner}/>    
    </div>
    Refresh
    </button>
  </>);
}



export {NumberButton, DeleteButton, ClearButton, CloseButton, RefreshButton};
