import log from "loglevel";
import {DEBIT_FEE_TYPE, CREDIT_FEE_TYPE, QPITCH_FEE_TYPE, CNP_FEE_TYPE, getFee} from '@constants/fee_types';
import {ACCOUNTS} from "@constants/accounts";

export type DailySummary = {
  total: number;
  debitFees: number;
  creditFees: number;
  qpitch: number;
  accounts: { [key: string]: number; };
  day: number;
  remitted: number;
}

function getDailyTotal(day: any) {

  return  getDailyFloorTotal(day, "0") + 
          getDailyFloorTotal(day, "1") +
          getDailyFloorTotal(day, "2") +
          getDailyFloorTotal(day, "3");
}

function getDailyFloorTotal(day: any, floor: string) {

  return day[floor]["0"].total +
        day[floor]["1"].total +
        day[floor]["2"].total;
}

function getDailyDebitCardFees(day: any, date: Date) {

  return  getDailyFloorDebitCardFees(day, "0", date) + 
          getDailyFloorDebitCardFees(day, "1", date) +
          getDailyFloorDebitCardFees(day, "2", date) +
          getDailyFloorDebitCardFees(day, "3", date);
}

function getDailyFloorDebitCardFees(day: any, floor: string, date: Date) {

  return  (day[floor]["1"].total * getFee(DEBIT_FEE_TYPE, date)) + 
          (day[floor]["1"].cnp * getFee(CNP_FEE_TYPE, date)) + 
          (day[floor]["1"].refunded * getFee(DEBIT_FEE_TYPE, date) * 2); 
}

function getDailyCreditCardFees(day: any, date: Date) {

  return  getDailyFloorCreditCardFees(day, "0", date) + 
          getDailyFloorCreditCardFees(day, "1", date) +
          getDailyFloorCreditCardFees(day, "2", date) +
          getDailyFloorCreditCardFees(day, "3", date);
}

function getDailyFloorCreditCardFees(day: any, floor: string, date: Date) {

  return (day[floor]["2"].total * getFee(CREDIT_FEE_TYPE, date)) + 
          (day[floor]["2"].cnp * getFee(CNP_FEE_TYPE, date))
}

function getDailyQPitch(day: any) {

  return  day["0"]["0"].qpitch +
          day["0"]["1"].qpitch +
          day["0"]["2"].qpitch;
}

export function createDailySales(data: any) {

  log.debug("createDailySales", data);
  const sales : DailySummary[] = [];

  if (data !== undefined) {
    data.forEach((day: any) => {
      log.debug("day", data);
      const month = day.month.split("-");
      const date = new Date(parseInt(month[0]), parseInt(month[1]), day.day);
      const dailyTotal = getDailyTotal(day);
      const dailyDebitFees = getDailyDebitCardFees(day, date);
      const dailyCreditFees = getDailyCreditCardFees(day, date);
      const qpitchFee = getDailyQPitch(day) * getFee(QPITCH_FEE_TYPE, date);
      var remitted = 0;

      //new property so might not exist
      if (day.remitted) {
        remitted = day.remitted;
      }

      log.debug("daily sale:", dailyTotal, dailyDebitFees, qpitchFee);

      sales.push({total: dailyTotal, debitFees: dailyDebitFees, creditFees: dailyCreditFees, qpitch: qpitchFee, day: day.day, accounts: day.internal, remitted: remitted});
    });
  }
  return sales;
}

function sumAccounts(accounts: { [key: string]: number; }, dailyTotal: DailySummary) {

  ACCOUNTS.forEach((account: string) => {
    accounts[account] += dailyTotal.accounts[account];
  }); 
}

export function createMonthlyTotals(data: DailySummary[]) {

  var total = 0;
  var debitFees = 0;
  var creditFees = 0;
  var qpitch = 0;
  var remitted = 0;
  const accounts: { [key: string]: number; } = {};

  ACCOUNTS.forEach((account: string) => {
    accounts[account] = 0;
  });  

  data.forEach((dailyTotal: DailySummary) => {
    total = total += dailyTotal.total;
    debitFees = debitFees += dailyTotal.debitFees;
    creditFees = creditFees += dailyTotal.creditFees;
    qpitch = qpitch += dailyTotal.qpitch;
    remitted = remitted += dailyTotal.remitted
    sumAccounts(accounts, dailyTotal);
  });

  return {total: total, debitFees: debitFees, creditFees: creditFees, qpitch: qpitch, day: 0, accounts: accounts, remitted: remitted};
} 