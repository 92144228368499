import Shell from "@shared/Shell";
import log from "loglevel";
import useSale from "@hooks/useSale";
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import dateFormat from "dateformat";
import { formatPrice } from "@shared/Utils";
import {SpinnerWrapper} from "@shared/Spinner";
import {  CalendarDaysIcon, 
          CreditCardIcon, 
          UserCircleIcon, 
          CurrencyPoundIcon,
          BuildingOfficeIcon,
          IdentificationIcon,
          PencilIcon,
          FingerPrintIcon,
          FlagIcon,
          CheckCircleIcon,
          XCircleIcon
        } from '@heroicons/react/20/solid'
import { getDescription } from "@constants/payment_types";
import { DESCRIPTIONS } from "@constants/status_types";

import { FLOORS } from "@constants/floors";
import useStallholders from "@hooks/useStallholders";


export function Sale() {

  const location = useLocation();
  const [saleID, setSaleID] = useState("");

  useEffect(() => {
    
    try {
      const { pathname } = location;
      const id = pathname.split("/")[2];
      setSaleID(id);
    }
    catch (err) {
      log.error(err);
    }
    return () => {
    };
  }, [location]); 

  return (
    <>
      <Shell pageIndex={-1}>
        <div>
          {
            (saleID.length > 0)
            ?
              <Details saleId={saleID} />
            :
              <></>
          }
        </div>
      </Shell>    
    </>
  );
}


type DetailsProps = {
  saleId: string;
};

const Details = ({saleId}: DetailsProps) => {

  log.debug("loading sale:", saleId);
  const {isLoading, data, refetch} = useSale(saleId);
  const [stallholderName, setStallholderName] = useState("");
  const [tillName, setTillName] = useState("");
  const stallholderContext = useStallholders();

  useEffect(() => {
    
    if (!isLoading) {

      log.debug("loaded:", data);
      const stallholder = stallholderContext.findStallholderByDealerID(data.Items[0].stallholder);
      if (stallholder) setStallholderName(stallholder.name);
      const till = stallholderContext.findStallholderByDealerID(data.Items[0].till);
      if (till) setTillName(till.name);
    }
    return () => {
    };
  }, [isLoading]); 

  return(
    <>
      
      {(!isLoading) 
      ?
        <>
        {
          data.Count === 0
          ?
            <>ID not recognised</>
          :
            <>
              <div className="lg:col-start-3 lg:row-end-1">
                <h2 className="sr-only">Summary</h2>
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                  <dl className="flex flex-wrap">
                  
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Status</span>
                        <FlagIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                          {DESCRIPTIONS[data.Items[0].status]}
                      </dd>
                    </div>

                    <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                      <dt className="flex-none">
                        <span className="sr-only">Stallholder</span>
                        <UserCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm font-medium leading-6 text-gray-900">
                        {stallholderName}
                         (
                        {data.Items[0].stallholder}
                         )
                      </dd>
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Date</span>
                        <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        <time >
                          {dateFormat(data.Items[0].created, "dddd, mmmm dS")}
                        </time>
                      </dd>
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Price</span>
                        <FingerPrintIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                          {data.Items[0].stockid}
                      </dd>
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Price</span>
                        <PencilIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                          {data.Items[0].description}
                      </dd>
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Price</span>
                        <CurrencyPoundIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                          {formatPrice(data.Items[0].price)}
                      </dd>
                    </div>
                    
                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Payment type</span>
                        <CreditCardIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        {
                          getDescription(data.Items[0].paymentType)
                        }
                      </dd>
          
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Floor</span>
                        <BuildingOfficeIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        {
                          FLOORS[data.Items[0].floor]
                        }
                      </dd>
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Till Operator</span>
                        <IdentificationIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        {tillName}
                         (
                        {data.Items[0].till}
                         )
                      </dd>
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">QPitch</span>
                        <span className="h-6 w-5 text-gray-400" >
                          QPitch
                        </span>
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        {
                          (data.Items[0].isQPitch)
                          ?
                            <CheckCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                          :
                            <XCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                        }
                         
                      </dd>
                    </div>

                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Cardholder not present</span>
                        <span className="h-6 w-5 text-gray-400" >
                          CNP
                        </span>
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        {
                          (data.Items[0].isCNP)
                          ?
                            <CheckCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                          :
                            <XCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                        }
                         
                      </dd>
                    </div>

                  </dl>
                  <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                  </div>
                </div>
              </div>
            </>
        }
        </>
      :
        <SpinnerWrapper/>
      }
    </>
  );
}         

export default Sale;
