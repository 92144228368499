//import { ReactComponent as helpIcon } from "../../assets/";
import { ReactComponent as AnimatedSpinner } from '@sharedassets/animated_spinner.svg';
import { ReactComponent as StaticSpinner } from '@sharedassets/spinner.svg';

/**
 * 
 * 
 * Example setting size and color:
    <div className="bg-white text-black h-96 w-96">

    
    <svg className="animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    </div>
 * 
 */

type SpinnerProps = {
  spin?: boolean;
};

const Spinner = ({ spin }: SpinnerProps) => {
  return(
    <>
      {
        spin ?
        <AnimatedSpinner /> :
        <StaticSpinner />    
      }

    </>
  );
}

function SpinnerWrapper () {
  return (
    <div className="bg-white text-slate-500 h-32 w-32">
      <Spinner spin={true}/>
    </div> 
  );
}



export {Spinner, SpinnerWrapper};
