import { useQuery } from '@tanstack/react-query'
import useAdminAPIService from "@hooks/useAdminAPIService";
import log from "loglevel";
import { processTransactions } from "@shared/Utils";

type TransactionsQueryType = (
  start: string,
  end: string,
  stallholder: string
) => any;

const useStallholderTransactions: TransactionsQueryType = (
  start, end, stallholder
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useAdminAPIService();
  const key = ['transactions:', stallholder, start, end];
  return useQuery({
    queryKey: key, 
    queryFn: async () => 
      apiService.fetch("getStallholderTransactions", {start: start, end: end, stallholder: stallholder})
      .then((data: any) => {  
          log.debug("useStallholderTransactions, result:" + data);
          return processTransactions(data);

      })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 1000000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
})
}

export default useStallholderTransactions;
