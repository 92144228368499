import { debug } from "console";
import log from "loglevel";

export const CREDIT_FEE_TYPE = 10;
export const DEBIT_FEE_TYPE = 11;
export const QPITCH_FEE_TYPE = 12;
export const CNP_FEE_TYPE = 13;


const FEE_CHANGE_DATE_1 = new Date(2024, 9, 1);//1 Oct 2024
const FEE_CHANGE_DATE_2 = new Date(2024, 10, 1);//1 Nov 2024

export function getFee(type: number, created:Date) {

    if (created < FEE_CHANGE_DATE_1) {
        switch (type) {
            case CREDIT_FEE_TYPE: return 0.025;
            case DEBIT_FEE_TYPE: return 0.01;
            case QPITCH_FEE_TYPE: return 0.125;
            case CNP_FEE_TYPE: return 0.15;
            default: return 0;
        }
    }
    else if (created < FEE_CHANGE_DATE_2) {
        switch (type) {
            case CREDIT_FEE_TYPE: return 0.025;
            case DEBIT_FEE_TYPE: return 0.015;
            case QPITCH_FEE_TYPE: return 0.125;
            case CNP_FEE_TYPE: return 0.15;
            default: return 0;
        }
    }
    else {
        switch (type) {
            case CREDIT_FEE_TYPE: return 0.025;
            case DEBIT_FEE_TYPE: return 0.015;
            case QPITCH_FEE_TYPE: return 0.15;
            case CNP_FEE_TYPE: return 0.15;
            default: return 0;
        }
    }

}

export function getFee2(type: number, created:string) {

    return getFee(type, new Date(created));
}
