import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "@screens/ErrorPage";
import { useState } from 'react'
import { AdminAuthProvider } from "@components/ContextProviders/AdminAuthContext";
import { AdminAPIServiceProvider } from "@components/ContextProviders/AdminAPIServiceContext";
import { StallholdersProvider } from "@contexts/StallholdersContext";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify';
import Reconciliations from "@components/Reconciliations";
import Dealers from "@components/Dealers";
import Search from "@components/Search";

import Sales from "@components/Sales";
import MonthlySales from "@components/MonthlySales";
import Sale from "@components/Sale";

import Dealer from "@components/Dealer";

import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { COGNITO_APP_ID, COGNITO_POOL_ID } from "@constants/urls";
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { NavigationProvider } from "@shared/ContextProviders/NavigationContext";
import {setLogLevel} from "@shared/Utils";
import log from "loglevel";

import './App.css';
import '@aws-amplify/ui-react/styles.css';


const queryClient = new QueryClient()

setLogLevel();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Reconciliations />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Reconciliations />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dealers",
    element: <Dealers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sales",
    element: <Sales />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sales/:id",
    element: <MonthlySales />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dealer/:id",
    element: <Dealer />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/search",
    element: <Search />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sale/:id",
    element: <Sale />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signout",
    element: <SignOut />,
  }
]);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: COGNITO_APP_ID,
      userPoolId: COGNITO_POOL_ID,
    }
  }
});

export function App({ signOut, user }: WithAuthenticatorProps) {

  const [pages] = useState([
    { name: 'Reconciliations', href: '/home', index: 0},
    { name: 'Dealers', href: '/dealers', index: 1},
    { name: 'Sales', href: '/sales', index: 2},
    //{ name: 'Search', href: '/search', index: 2}
  ]);

  return (
    <div className="App">
      
      <NavigationProvider pages={pages}>
        <AdminAuthProvider>
          <AdminAPIServiceProvider>
            <QueryClientProvider client={queryClient}>
              <StallholdersProvider>
                <RouterProvider router={router} />
              </StallholdersProvider>
            </QueryClientProvider>
          </AdminAPIServiceProvider>
        </AdminAuthProvider>
      </NavigationProvider>

    </div>
  );
}

function SignOut() {

  const navigate = useNavigate();

  try {
    signOut();
    navigate("/");
  } catch (error) {
    log.warn('error signing out: ', error);
  }

  return (<></>);
}

export default withAuthenticator(App, { hideSignUp: true });
