import { useQuery } from '@tanstack/react-query'
import useAdminAPIService from "@hooks/useAdminAPIService";
import log from "loglevel";

type SalesQueryType = (
  id: string
) => any;

const useSale: SalesQueryType = (
  id
) => {

  const apiService  = useAdminAPIService();
  const key = ['sale:', id];
  return useQuery({
    queryKey: key, 
    queryFn: async () => 
      apiService.fetch("getTransaction", {id: id})
      .then((data: any) => {  
          log.debug("useSale, result:", data);
          return data;
      })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 1000000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
})
}

export default useSale;
