import { ReactNode, createContext } from "react";

export type Page = {
  name: string;
  href: string;
  index: number;
 }
 
const NavigationContext = createContext<NavigationContextValue | undefined>(undefined);

type NavigationContextValue = {
  pages: Page[];
};

type NavigationContextProps = {
  children?: ReactNode;
  pages: Page[];
};

export const NavigationProvider = ({ children, pages }: NavigationContextProps) => {

  const value: NavigationContextValue = {
    pages,
  }

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};



export default NavigationContext;
