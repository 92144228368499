import Shell from "@shared/Shell";
import {SpinnerWrapper} from "@shared/Spinner";
import useSearch from "@hooks/useSearch";

import dateFormat from "dateformat";
import { useState, useEffect } from 'react'
import log from "loglevel";



export function Search() {

  //const stallholderContext = useStallholders();
  const results = useSearch("ffff", "gggg");


  return (
    <>
      <Shell pageIndex={3}>
        <div>
          Search
          
        </div>
      </Shell>    
    </>
  );
}

export default Search;
